.marken{
  @include grid-row(12, null, rem-calc(800), true, 5rem);
  padding: 2rem;
  margin: auto;
  overflow: hidden;

  .marke{

    //@include clearfix;
    @include grid-col(12);

    border: 2px solid #c79d6d;
    position: relative;
    margin: 1rem 0;

    padding: 0!important;

    &:before{
      content: '';
      position: absolute;
      top:20%;
      left:-2px;
      width: 2px;
      height: 60%;
      background: #242424;
    }

    &:after{
      content: '';
      position: absolute;
      top:20%;
      right:-2px;
      width: 2px;
      height: 60%;
      background: #242424;
    }



    .bg{
      position: relative;
      overflow: hidden;

      &:before{
        content: '';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: calc(100% + 1px);


        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#242424+0,242424+80,242424+100&0+0,0+40,0.4+50,0.8+60,1+80,1+100 */
        background: -moz-linear-gradient(left, rgba(36,36,36,0) 0%, rgba(36,36,36,0) 40%, rgba(36,36,36,0.4) 50%, rgba(36,36,36,0.8) 60%, rgba(36,36,36,1) 80%, rgba(36,36,36,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(36,36,36,0) 0%,rgba(36,36,36,0) 40%,rgba(36,36,36,0.4) 50%,rgba(36,36,36,0.8) 60%,rgba(36,36,36,1) 80%,rgba(36,36,36,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(36,36,36,0) 0%,rgba(36,36,36,0) 40%,rgba(36,36,36,0.4) 50%,rgba(36,36,36,0.8) 60%,rgba(36,36,36,1) 80%,rgba(36,36,36,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00242424', endColorstr='#242424',GradientType=1 ); /* IE6-9 */

      }

      &:after{
        content: '';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background: #242424;
        opacity: 0.2;
      }
    }

    .logo{
      position: absolute;
      right: 2rem;
      top: 50%;
      width: 200px;

      //filter: invert(100%);
      //@include filter(invert, 100%);

      //transform: translateY(-50%);
      @include transform(translateY(-50%));

      @include breakpoint(small only){
        width: 100px;
        right: 1rem;
      }

    }

    .title{
      position: absolute;
      top: 50%;

      //transform: translateY(-50%);
      @include transform(translateY(-50%));

      text-align: center;
      color: white;


      right: 0rem;
      width: 150px;
      font-size: 1.1rem;

      @include breakpoint(medium up){
        width: 200px;
        font-size: 1.5rem;
        right: 2rem;
      }
    }
  }

}

.marke_small{

  @include grid-col(6,4rem);
  padding-top: 1rem;
  padding-bottom: 1rem;


  @include breakpoint(medium up){
    @include grid-col(4,6rem);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }


  .wrapper{

    height: 100px;

    @include breakpoint(medium up){
      height: 150px;
    }

    display: table;
    .inner{
      display: table-cell;
      vertical-align: middle;
    }
  }

  .logo{
    //filter: invert(100%);
    //@include filter(invert, 100%);
    max-height: 150px;
  }

  &:last-child{
    @include grid-column-end;
  }
}