.homeSlider{
  position: relative;
  //background: #f0f0f0 url('../img/bg.jpg') no-repeat scroll center / cover;


  .image{
    height: calc(100vh - 97px);

    background-color: #f0f0f0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(medium up){
      height: calc(100vh - 254px);
    }
  }

  @include breakpoint(medium up) {
    margin-top: 43px;
  }

}