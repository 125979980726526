.map{
  position: relative;
  width: 100%;
  height: 30vh;

  @include breakpoint(large up){
    position: fixed;
    height: calc(100% - 211px);
    top: 211px;
    width: 60.5%;
  }

  #gmap{
    height: 100%;
    width: 100%;
  }

  .gm-style-mtc{
    display: none;
  }
}