header{

  background: white;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 100;

  @include transition(background 300ms);

  .logo{

    padding: 2rem 0 1rem;

    img{
      display: block;
      width: rem-calc(200);
      margin: auto;

      @include filter(invert,0%);
      @include transition(filter 300ms);
    }

    &.big{
      img{
        width: rem-calc(300);
      }
    }

    @include breakpoint(small only){
      padding: 1rem;
      img{
        width: rem-calc(150)!important;
      }
    }
  }


  .lorbeer{

    position: absolute;
    top: 2rem;
    right: 2rem;
    opacity: 0.6;

    img {
      margin: 15px 0;
      height: 58px;
    }

    &.small img{
      margin: 15px 0;
      height: 58px;
    }

    &.big img{
      margin: 25px 0;
      height: 82px;
    }

    @include breakpoint(small only){

      top: 1rem;

      img{
        height: 36px!important;
        margin: 15px 0!important;
      }
    }

  }

  nav{
    padding: 1rem 0;
    .menu{
      li:not(:last-child){
        position: relative;
        &:after{
          content: "";
          position: absolute;
          top: 25%;
          right: 0;
          width: 1px;
          height: 50%;
          background: #cccccc;
        }
      }
      a{
        color: #666666;
        &:hover{
          color:#c79d6d;
        }
      }

      li{

        &.active{
          a{
            color: #c79d6d;
            background: none;
          }
        }
      }
    }

    @include breakpoint(small only){
      display: none;

      background: #242424;
      .menu{

        height: calc(100vh - 97px);
        overflow-y: scroll;

        @include menu-direction(vertical);


        width: 100%;
        padding: 0 1rem;


        li{
          border-bottom: 1px solid #c79d6d;
          a{
            text-align: left;
            color: #c79d6d;
            padding: 1.5rem 0;

          }
          &.active{
            a{
              color: white
            }
          }

          &:first-child{
            border-top: 1px solid #c79d6d;
          }

          &:not(:last-child){
            border-bottom: 1px solid #c79d6d;
            &:after{
              background: transparent;
            }
          }
        }
      }
    }

  }


  .menuicon{
    display: none;
    cursor: pointer;

    @include breakpoint(small only){
      display: block;
    }

    position: absolute;
    top: rem-calc(39);
    left: 1rem;
    padding: 10px 0;
    width: 30px;

    span{
      position: relative;
      background: #c79d6d;
      height: 2px;
      width: 30px;
      display: block;
      cursor: pointer;

      &:after, &:before{
        content: "";
        background: #c79d6d;
        height: 2px;
        width: 100%;
        display: block;
        position: absolute;
      }

      &:after{
        bottom: -10px;
      }
      &:before{
        top:-10px;
      }
    }
  }

}
.menuopen{
  header{
    background: #242424;

    .logo, .lorbeer{
      img{
        @include filter(invert, 100%)
      }
    }

    .menuicon{
      span{
        background: #242424;
        &:before{
          background: white;
          top: 0;

          @include rotate(45);
        }
        &:after{
          background: white;
          bottom: 0;

          @include rotate(-45);
        }
      }
    }
  }
}