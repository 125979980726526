
body{

  @include clearfix;
  overflow-y: scroll;
  background: #242424;
  position: relative;

  padding-top: rem-calc(97);

  @include breakpoint(medium up){
    padding-top: 211px;
  }

  /*&:before{
    content: '';
    position: fixed;
    background: #000000 url('../img/bg_texture.jpg') no-repeat scroll center / 100% 100%;
    opacity: 0.10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }*/
}

h1{
  font-weight: 700;
  font-style: italic;
}

p{
  font-style: italic;
}


.leftslider{

  //background: white;
  position: relative;
  width: 100%;
  height: 30vh;

  .image{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 30vh;
  }

  @include breakpoint(large up){
    position: fixed;
    height: calc(100% - 211px);
    top: 211px;
    width: 60%;

    .image{
      height: calc(100vh - 211px);
    }

    &:after{
      position: absolute;
      content: '';
      left: 60vw;
      top: 0;
      width: 40vw;
      height: 100%;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#242424+0,242424+100&0+0,0+40,0.4+50,0.8+60,1+80,1+100 */
      //background: -moz-linear-gradient(left, rgba(36,36,36,0) 0%, rgba(36,36,36,0) 40%, rgba(36,36,36,0.4) 50%, rgba(36,36,36,0.8) 60%, rgba(36,36,36,1) 80%, rgba(36,36,36,1) 100%); /* FF3.6-15 */
      //background: -webkit-linear-gradient(left, rgba(36,36,36,0) 0%,rgba(36,36,36,0) 40%,rgba(36,36,36,0.4) 50%,rgba(36,36,36,0.8) 60%,rgba(36,36,36,1) 80%,rgba(36,36,36,1) 100%); /* Chrome10-25,Safari5.1-6 */
      //background: linear-gradient(to right, rgba(36,36,36,0) 0%,rgba(36,36,36,0) 50%,rgba(36,36,36,0.4) 60%,rgba(36,36,36,0.8) 70%,rgba(36,36,36,1) 90%,rgba(36,36,36,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00242424', endColorstr='#242424',GradientType=1 ); /* IE6-9 */

      -webkit-box-shadow: -20px 0px 70px 80px rgba(36,36,36,1);
      -moz-box-shadow: -20px 0px 70px 80px rgba(36,36,36,1);
      box-shadow: -20px 0px 70px 80px rgba(36,36,36,1);



    }
  }

}

main{
  position: relative;
  color: #f0f0f0;

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
  }
  ul > li:before {
    display: inline-block;
    content: "-";
    width: 1em;
    margin-left: -1em;
  }

  &.withimage{

    padding: 2rem 1rem;

    @include breakpoint(large up){
      width: 40%;
      margin-left: 60%;
      padding: 3rem;

      max-width: rem-calc(550);
    }

  }

  a{
    color: #c79d6d;
    &:hover{
      color: lighten(#c79d6d, 10);
    }
  }


  .addon{

    position: relative;

    &:before{
      content: '';
      position: absolute;
      top: rem-calc(-50);
      left: 0;
      width: rem-calc(100);
      height: 1px;
      background: #c79d6d;
    }

    margin-top: rem-calc(100);
    color: #c79d6d;
    font-style: italic;
  }
}

//White Layout

.white{

  background: white;

  header{
    border-bottom: 1px solid $light-gray;
  }

  main{
    //background:white;
    color: #666666;

    h1{
      color: #242424;
    }
  }

  .leftslider{
    .image{
      background-size: contain;
    }

    &:after{
      background: none;

      //box-shadow: 0px 0px 50px 30px rgba(36,36,36,0.1);
      box-shadow: none;
    }
  }

}